import SyntaxHighlighter from 'react-syntax-highlighter';
import { Container, Divider, Grid, Header, Message, Segment, Table } from "semantic-ui-react";

const Sql = () => {



    return (
        <Container style={{ minWidth: '70%' }}>
            <Segment basic>
                <Segment style={{ marginTop: "0.2em" }}>
                    <Grid divided>
                        <Grid.Column width={12}>
                            <Header as="h2">SQL Database </Header>
                            <p>
                                The FL2F workshop uses a MySQL database to store all the information related to the workshop. The database is used to store information about the users, the lectures, the assignments, etc. 
                                The database is also used to store the information about the users' progress in the workshop like survey answers, assignment submissions, etc.
                            </p>
                            <p> 
                                Most of the FL2F apps used the sql express connector to connect to the database, tha authentication is done using the user and password it is stored in the google secrets manager, during the cloudbuild process the secrets credential json is downloaded 
                                and used to connect the secrets manager npm package to get the user and password to connect to the database.
                            </p>
                            <Divider />
                            <Header as="h3" id="access">Access</Header>
                            <p>
                                The database is hosted in the Google Cloud Platform, the database is a MySQL database and is hosted in the Cloud SQL service. 
                                The database is accessible from the Google Cloud Platform console, you need to have access to the project to access the database, and have the 
                                required permissions to access the database.
                            </p>
                            <Divider />
                            <Header as="h3" id="Usage">Usage</Header>
                            <p>
                                The schema most used by the FL2F apps is the following is the schema called Users and the schema called fl2f_smf, due to multiple changes in the FL2F workshop both of the schemas are used for multiple things in the workshop.
                            </p>
                            <Divider />
                            <Header as="h3" id="SQL IDE">SQL IDE</Header>
                            <p>
                                The IDE of recommendation to access the database is the DBeaver IDE, it is a free and open-source multi-platform database tool that supports all the major databases like MySQL, PostgreSQL, SQLite, etc.
                                The free version of the tool is enough to access the database and run queries, the tool is easy to use and has a lot of features that make it easy to work with the database. 
                            </p>

                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Segment basic>
                                <Header as="h3">Table of Contents</Header>
                                <Table  basic="very">
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell><a href="#access">Access</a></Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell><a href="#Usage">Usage</a></Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell><a href="#SQL IDE">SQL IDE</a></Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                                <Header as="h3">Links</Header>
                                <Table basic="very">
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell><a href="https://cloud.google.com/sql" target="_blank" rel="noreferrer">Google Cloud SQL</a></Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell><a href="https://dbeaver.io/" target="_blank" rel="noreferrer">DBeaver IDE</a></Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell><a href="https://www.mysql.com/" target="_blank" rel="noreferrer">MySQL</a></Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                                <Header as="h4">Details</Header>
                                <p>
                                    <strong>Created by:</strong> Santiago Fuentes
                                </p>
                                <p>
                                    <strong>Last Updated:</strong> 2024-09-12
                                </p>
                            </Segment>
                        </Grid.Column>
                    </Grid>
                </Segment>
            </Segment>
        </Container>
    );
}

export default Sql;