import { useEffect, useState } from "react";
import { Accordion, Button, Container, Divider, Grid, Header, Icon, Image, Menu, Message, Segment, Table } from "semantic-ui-react";
import ApplicationImg from '../assets/applications-page.png';
import TransferImg from '../assets/transfer.png';

const AdminGuide = () => {

    const [activeTab, setActiveTab] = useState('pre-work');

    const handleTabChange = (e, { name }) => setActiveTab(name);

    const contentStyle = {
        maxHeight: '79vh',  // Adjust this height as needed
        overflowY: 'auto',
        padding: '10px',
    };

    
    
    return (
        <Container style={{ minWidth: '85%' }}>
        <Segment basic>
            <Menu attached="top" tabular>
                <Menu.Item name="pre-work" active={activeTab === 'pre-work'} onClick={handleTabChange}>
                    Pre Workshop Tasks
                </Menu.Item>
                <Menu.Item name="workshop" active={activeTab === 'workshop'} onClick={handleTabChange}>
                    Workshop Tasks
                </Menu.Item>
                <Menu.Item name="post-work" active={activeTab === 'post-work'} onClick={handleTabChange}>
                    Post Workshop Tasks
                </Menu.Item>
            </Menu>
            <Segment attached="bottom">
                <Grid divided>
                    <Grid.Column width={12}>
                        <div style={contentStyle}>
                            {
                                activeTab === 'pre-work' && (
                                    <Segment basic>
                                        <Header as="h2">Pre-Workshop Tasks</Header>
                                        <p> You need an admin FL2F account in order to access the services mentioned below</p>
                                        <p>Optional instructions only happen sometimes depending on the workshop requirements, e.g., sending guest email is only required if the workshop has emails.</p>

                                        <Header dividing as="h3" id="1-2-months-before">1-2 Months Before Workshop</Header>
                                        {/* <Header as="h4" id="directory-and-group-creation">Directory and Group Creation (Optional)</Header>
                                        <p>Create a directory under the 2023 workshops directory named "Cohort Name Workshop Year".</p>
                                        <p>Create the workshop user group named "Cohort Name Workshop Year".</p> */}

                                        <Header as="h4" id="workshop-group-creation">Workshop Group Creation</Header>
                                        <p>Create a new group for the workshop using the <strong><a href="https://user-management.fl2f.ca/" target="_blank" rel="noreferrer">User Management</a></strong> tool, or verify that the group has been created already.</p>

                                        <Header as="h4" id="calendar-setup">Calendar Setup</Header>
                                        <p>Verify that the calendar has been created already. If not, create a new calendar for the workshop using the <strong><a href="https://calendar.fl2f.ca/" target="_blank" rel="noreferrer">Calendar Scheduler</a></strong>.</p>
                                        <p>Create the workshop calendar named "(Cohort Name)-(Workshop Year)", e.g., Spring-2024, selecting the corresponding group and default schedule.</p>

                                        <Header as="h4" id="application-surveys">Application Surveys</Header>
                                        <p>Send the application survey via email using the generated apply link in the <strong><a href="https://operations.fl2f.ca/" target="_blank" rel="noreferrer">Operation Tracker</a></strong> inside the application page.</p>
                                        <p>Go to <strong><a href="https://operations.fl2f.ca/" target="_blank" rel="noreferrer">Operations</a></strong>  -&gt; Application page</p>
                                        <Image src={ApplicationImg} spaced bordered />

                                        <p style={{ marginTop: "1em" }}>Since no participants have been registered at this point, use your personal work email to send these. Make sure to select the correct group.</p>

                                        <Header as="h4" id="participant-selection">Participant Selection</Header>
                                        <p> Go to <strong><a href="https://user-management.fl2f.ca/" target="_blank" rel="noreferrer"> User Management </a></strong> -&gt; Transfer Page </p>
                                        <p>Transfer approved applications to the cohort group once participants are selected and have completed the application survey using the <strong><a href="https://user-management.fl2f.ca/" target="_blank" rel="noreferrer">User Management</a></strong> transfer page.</p>
                                        <Image src={TransferImg} spaced bordered />

                                        <Header as="h4" id="facilitator-assignment">Facilitator Assignment</Header>
                                        <p>Assign a facilitator by selecting the facilitators button using the <strong><a href="https://user-management.fl2f.ca/" target="_blank" rel="noreferrer">User Management</a></strong> tool inside the workshop group.</p>
                                        <p>Facilitators will send carbon copy emails and appear in peer assessments. It is recommended to assign Dr. Orly and yourself as facilitators.</p>

                                        <Header as="h4" id="credentials-creation">Credentials Creation (Optional) </Header>
                                        <p>Create credentials for guest speakers, institute VIPs, and alumni if needed.</p>
                                        <p>Notify facilitators about their credentials using the email from the Email List.</p>

                                        <Header as="h4" id="excerpts-folder">Excerpts Folder (Optional)</Header>
                                        <p>This is done automatically at the beginning of the year, no need to do this manually.</p>
                                        <p>Ensure the Excerpts folder content is watermarked and password-protected (password: FL2FYear, e.g., FL2F2022). These passwords update automatically every year.</p>
                                        <p>This are some of the documents used during the workshop.</p>

                                        <Header dividing as="h3" id="1-month-before">1 Month Before Workshop</Header>

                                        <Header as="h4" id="calendar-verification">Calendar Verification</Header>
                                        <p>Go to <strong><a href="https://calendar.fl2f.ca/" target="_blank" rel="noreferrer">Calendar Scheduler</a></strong> and verify if the calendar is created for the workshop, note that the workshop group needs to exist to be able to create the calendar for the group.</p>

                                        <Header as="h4" id="guest-communication">Guest Communication (Optional)</Header>
                                        <p>Reach out to guests using specific emails from the Email List (<strong><a href="https://operations.fl2f.ca/emails#b9f24981-279e-4a4e-8564-3cd1e7d846cc" target="_blank" rel="noreferrer">Request for guest speakers</a></strong>, <strong><a href="https://operations.fl2f.ca/emails#06263bd4-bb72-4156-8744-180d759968e8" target="_blank" rel="noreferrer">for VIP guest</a></strong>, <strong><a href="https://operations.fl2f.ca/emails#a77fd696-4759-4d1e-8669-09b484a58562" target="_blank" rel="noreferrer">for alumni guest</a></strong>).</p>

                                        <Header as="h4" id="guest-invitations">Guest Speaker and Alumni Invitations (Optional)</Header>
                                        <p>Send email <strong><a href="https://operations.fl2f.ca/emails#8fb29a40-0fd7-4439-a026-1db4cbbda6eb" target="_blank" rel="noreferrer">Guest Talk details</a></strong> to guest speakers who accept the invitation, and <strong><a href="https://operations.fl2f.ca/emails#437ad04b-e157-4613-a04f-35d77450e9fc" target="_blank" rel="noreferrer">Alumni Panel details</a></strong> email.</p>

                                        <Header dividing as="h3" id="2-3-weeks-before">2-3 Weeks Before Workshop</Header>

                                        <Header as="h4" id="application-verification">Application Verification</Header>
                                        <p>Verify receipt of all approved user application surveys. Transfer applications to the specific workshop group if needed.</p>
                                        <p>Go to <strong><a href="https://user-management.fl2f.ca/transfer" target="_blank" rel="noreferrer">User Management Transfer</a></strong>. Admin account required.</p>

                                        <Header as="h4" id="candidate-communication">Candidate Communication</Header>
                                        <p>Send email <strong><a href="https://operations.fl2f.ca/emails#70b9f13a-cbe3-415d-8d13-a82986f62794" target="_blank" rel="noreferrer">Cohort-Welcome</a></strong> to workshop candidates from their group.</p>
                                        <p>Send email <strong><a href="https://operations.fl2f.ca/emails#97be1cf4-8dec-4a7a-82a2-1bb4a6bf05d6" target="_blank" rel="noreferrer">Next-Steps</a></strong> to workshop participants.</p>

                                        <Header as="h4" id="survey-completion">Survey Completion</Header>
                                        <p>Check completion, within operation tracker group status, of the Pre-Workshop Survey and Consent Survey. Send individual reminders if needed.</p>
                                        <p>Send <strong><a href="https://operations.fl2f.ca/emails#4c644b14-c650-41b9-83fe-a81a30754fdc" target="_blank" rel="noreferrer">REMINDER-Consent-Prework-Surveys</a></strong> if the participant is missing both surveys, if missing one use <strong><a href="https://operations.fl2f.ca/emails#c64481b9-15b4-4686-9c0b-49967a01a031" target="_blank" rel="noreferrer">REMINDER-Consent-Survey</a></strong> or <strong><a href="https://operations.fl2f.ca/emails#4f99afda-be95-4e25-8eff-028e699d8dca" target="_blank" rel="noreferrer">REMINDER-Preworkshop-Survey</a></strong>.</p>
                                        <p><em>Note: Consent survey status has to be changed manually.</em></p>

                                        <Header as="h4" id="participant-ideas-review">Participant Ideas Review</Header>
                                        <p>Contact Professor Orly to review participant ideas. Send email to participant <strong><a href="https://operations.fl2f.ca/emails#d95abb36-f953-43b2-af6d-7d5c5e99ccf5" target="_blank" rel="noreferrer">Update/provide-idea</a></strong> if ideas are insufficient.</p>
                                        <p>Track progress on the operator website.</p>

                                        <Header as="h4" id="form-completion">Form Completion</Header>
                                        <p>Send <strong><a href="https://operations.fl2f.ca/emails#e005a82d-ef04-4a8c-8824-6c68b6ce7e05" target="_blank" rel="noreferrer">One-on-One Meeting Schedule</a></strong> once forms are completed.</p>
                                        <p>Send daily reminder emails if confidentiality and consent forms are not signed.</p>

                                        <Header dividing as="h3" id="1-week-before">1 Week Before Workshop</Header>

                                        <Header as="h4" id="account-group-confirmation">Account Group Confirmation</Header>
                                        <p>Confirm all candidates are in their specific workshop group inside the operations tracker and user management.</p>

                                        <Header as="h4" id="participant-email">Participant Email</Header>
                                        <p>Send <strong><a href="https://operations.fl2f.ca/emails#ff61f97d-2936-48d7-b6a0-62046f59ad4d" target="_blank" rel="noreferrer">Important pre-workshop details email</a></strong> one week before the workshop.</p>
                                        <p>Follow up with <strong><a href="https://operations.fl2f.ca/emails#e4ce8ba1-9d5d-41e4-a2eb-0f94d6af469e" target="_blank" rel="noreferrer">Reminder about the Zoom link</a></strong> email three days later.</p>
                                    </Segment>


                                )

                            }
                            {
                                activeTab === 'workshop' && (
                                    <Segment basic>
                                        <Header as="h2">Workshop Tasks</Header>

                                        <Header dividing as="h3" id="after-every-meeting">After Every Group Meeting</Header>
                                        <p>Open the respective assignment and video section.</p>

                                        <Header dividing as="h3" id="group-meeting-specific-tasks">Group Meeting Specific Tasks</Header>
                                        <p>The following emails need to be sent after the mentioned group meeting.</p>

                                        <Header as="h4" id="group-meeting-1">Group Meeting #1</Header>
                                        <p>Send email <strong><a href="https://operations.fl2f.ca/emails#25eb13dd-04cf-424f-adce-89420aa63ab3" target="_blank" rel="noreferrer">Website-link</a></strong> to participants.</p>

                                        <Header as="h4" id="group-meeting-3">Group Meeting #3</Header>
                                        <p>Send emails <strong><a href="https://operations.fl2f.ca/emails#5cfee643-93f1-4e74-ac4c-1912be208cf2" target="_blank" rel="noreferrer">Section 4 Case Study email - DAN</a></strong>, <strong><a href="https://operations.fl2f.ca/emails#02aebf11-8a53-4f21-a66e-d8d321c11943" target="_blank" rel="noreferrer">- DANA</a></strong> to participants after verifying roles with Dr. Orly.</p>

                                        <Header as="h4" id="group-meeting-4">Group Meeting #4</Header>
                                        <p>Send email <strong><a href="https://operations.fl2f.ca/emails#bc76a07d-72b3-479b-b07e-418db00c350c" target="_blank" rel="noreferrer">Guest Talk reminder</a></strong> to the first guest speaker if needed.</p>
                                        <p>Send thank-you email <strong><a href="https://operations.fl2f.ca/emails#72b5a404-ebf0-4696-a1ec-88d563363ba2" target="_blank" rel="noreferrer">Thank-you email to the guest speaker</a></strong> to the guest speaker if there is one.</p>

                                        <Header as="h4" id="group-meeting-5">Group Meeting #5</Header>
                                        <p>Follow up with <strong><a href="https://operations.fl2f.ca/emails#bbd499d7-3ddf-4f75-8390-c034e48ad228" target="_blank" rel="noreferrer">Post-workshop survey</a></strong> email to all participants.</p>

                                        <Header as="h4" id="group-meeting-6">Group Meeting #6</Header>
                                        <p>Send post-workshop survey reminder email <strong><a href="https://operations.fl2f.ca/emails#65201b45-eed1-46ff-8a09-64d2cb4392a8" target="_blank" rel="noreferrer">Please fill in the Post Workshop Survey</a></strong>.</p>
                                        <p>Send email <strong><a href="https://operations.fl2f.ca/emails#8148ee7f-2bd2-4a26-a8fe-cb790efa28d8" target="_blank" rel="noreferrer">Point-of-Contact Satisfaction Survey email</a></strong>. Not required in workshop within UCalgary.</p>
                                        <p>Generate and send the spider graph for workshop statistics to Dr. Orly.</p>

                                        <Header dividing as="h3" id="certificate-ceremony-tasks">Certificate Ceremony Tasks</Header>

                                        <Header as="h4" id="agenda-preparation">Agenda Preparation (Optional)</Header>
                                        <p>Send email <strong><a href="https://operations.fl2f.ca/emails#1e1db390-c366-407e-a57f-abec37821424" target="_blank" rel="noreferrer">Reminder for Slide Deck</a></strong> to Professor Orly for agenda preparation.</p>
                                        <p>Send <strong><a href="https://operations.fl2f.ca/emails#2b4885ab-d0f0-41a6-a7dd-7fdcb93e94fd" target="_blank" rel="noreferrer">Agenda for Certificate Handing Ceremony</a></strong> email.</p>
                                        <p>Send <strong><a href="https://operations.fl2f.ca/emails#bc76a07d-72b3-479b-b07e-418db00c350c" target="_blank" rel="noreferrer">Guest Talk reminder</a></strong> email to the second guest speaker.</p>

                                        <Header as="h4" id="day-before-ceremony">Day Before Ceremony (Optional)</Header>
                                        <p>Send <strong><a href="https://operations.fl2f.ca/emails#df510a37-cb11-4756-8a7c-0ac04679e252" target="_blank" rel="noreferrer">Certificate Ceremony reminder</a></strong> email to guests.</p>
                                        <p>Follow up with <strong><a href="https://operations.fl2f.ca/emails#124e29da-c7e5-439c-af0c-1fca2d3543c4" target="_blank" rel="noreferrer">Point-of-Contact Satisfaction Survey</a></strong> reminder email.</p>

                                        <Header as="h4" id="during-ceremony">During Ceremony (Important)</Header>
                                        <p>Take screenshots for group photos and prepare the certificate handling ceremony photo.</p>
                                    </Segment>

                                )
                            }
                            {
                                activeTab === 'post-work' && (
                                    <Segment basic>
                                        <Header as="h2">Post-Workshop Tasks</Header>

                                        <Header dividing as="h3" id="linkedin-posts">LinkedIn Posts</Header>
                                        <p>Draft LinkedIn post about the workshop and send it to Prof. Orly for review.</p>
                                        <p>Draft individual participant posts using quotes and headshots, then send for approval.</p>

                                        <Header dividing as="h3" id="certificate-ceremony-tasks">Email Tasks</Header>

                                        <Header as="h4" id="guest-speaker-thank-you">Guest Speaker Thank-You (Optional)</Header>
                                        <p>Send thank-you email <a href="https://operations.fl2f.ca/emails#72b5a404-ebf0-4696-a1ec-88d563363ba2" target="_blank" rel="noreferrer">72b</a> to the second guest speaker.</p>

                                        <Header as="h4" id="follow-up-emails">Follow-Up Emails (Optional)</Header>
                                        <p>Send thank-you emails to institute VIPs and alumni guests with the ceremony photo attached one week after the Certificate Ceremony.</p>

                                        <Header as="h4" id="linkedin-reminder">LinkedIn Reminder</Header>
                                        <p>Remind Professor Orly to post the second LinkedIn post two weeks after the Certificate Ceremony.</p>
                                    </Segment>
                                )
                            }
                        </div>
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Segment basic>
                            <Header as="h3">Table of Contents</Header>
                            <Table basic="very">
                                <Table.Body>
                                    {
                                        activeTab === 'pre-work' && (
                                            <>
                                                <Table.Row>
                                                    <Table.Cell><a href="#1-2-months-before">1-2 Months Before Workshop</a></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell><a href="#1-month-before">1 Month Before Workshop</a></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell><a href="#2-3-weeks-before">2-3 Weeks Before Workshop</a></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell><a href="#1-week-before">1 Week Before Workshop</a></Table.Cell>
                                                </Table.Row>
                                            </>
                                        )
                                    }
                                    {
                                        activeTab === 'workshop' && (
                                            <>
                                                <Table.Row>
                                                    <Table.Cell><a href="#after-every-meeting">After Every Group Meeting</a></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell><a href="#group-meeting-specific-tasks">Group Meeting Specific Tasks</a></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell><a href="#certificate-ceremony-tasks">Certificate Ceremony Tasks</a></Table.Cell>
                                                </Table.Row>
                                            </>
                                        )
                                    }
                                    {
                                        activeTab === 'post-work' && (
                                            <>
                                                <Table.Row>
                                                    <Table.Cell><a href="#linkedin-posts">LinkedIn Posts</a></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell><a href="#certificate-ceremony-tasks">Email Tasks</a></Table.Cell>
                                                </Table.Row>
                                            </>
                                        )
                                    }
                                </Table.Body>
                            </Table>
                            {/* <Header as="h3">Links</Header>
                            <Table basic="very">
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell><a href="https://github.com/I2SenseLab/FL2F-User-Management-App" target="_blank" rel="noreferrer">User Management App Repo</a></Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell><a href="https://vertabelo.com/blog/crow-s-foot-notation/" target="_blank" rel="noreferrer">Crow’s Foot Notation</a></Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table> */}
                            <Header as="h4">Details</Header>
                            <p>
                                <strong>Created by:</strong> Santiago Fuentes
                            </p>
                            <p>
                                <strong>Last Updated:</strong> 2024-09-12
                            </p>
                        </Segment>
                    </Grid.Column>
                </Grid>
            </Segment>
        </Segment>
    </Container>
    );



}

export default AdminGuide;