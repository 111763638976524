import SyntaxHighlighter from 'react-syntax-highlighter';
import { Container, Divider, Grid, Header, Message, Segment, Table } from "semantic-ui-react";

const DockerAnsible = () => {

    return (
        <Container style={{ minWidth: '70%' }}>
            <Segment basic>
                <Message
                    warning
                    header="Warning"
                    content="Do not use portainer version 2.19.0 or higher, some needed api endpoints are not available in these newer versions. Only use versions below including 2.18.4."
                />
                <Segment style={{ marginTop: "0.2em" }}>
                    <Grid divided>
                        <Grid.Column width={12}>
                            <Header as="h2">Docker Ansible Setup Guide</Header>
                            <p>
                                FL2F Apps are deployed using Portainer for docker management with Ansible for automatic setup, configuration and deployment of the apps.
                            </p>
                            <Header as="h3" id="installation">Installation</Header>
                            <p>
                                You need to have ansible installed in your system so the automatic FL2F apps setup can be done. See commands below to install ansible.
                                <a href="https://docs.ansible.com/ansible/latest/installation_guide/intro_installation.html" target="_blank" rel="noreferrer"> More info</a>
                            </p>
                            <Grid>
                                <Grid.Column width={8}>
                                    <Header as="h4">Ubuntu</Header>
                                    <SyntaxHighlighter language="bash">
                                        sudo apt update
                                        sudo apt install ansible
                                    </SyntaxHighlighter>
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    <Header as="h4">Fedora</Header>
                                    <SyntaxHighlighter language="bash">
                                        sudo dnf install ansible
                                    </SyntaxHighlighter>
                                </Grid.Column>
                            </Grid>
                            <Header as="h3" id="usage">Usage</Header>
                            <p>
                                Clone the Ansible Playbooks repo from the link above. The repo contains the playbooks for the FL2F apps.
                                You need to have access to the server where the apps are to be deployed as well as the ansible vault password to decrypt the vaulted variables.
                                Ask administrator for the vault password, it is not stored in the repo for obvious reasons. Used the following command to run the playbooks.
                            </p>
                            <SyntaxHighlighter language="bash">
                                ansible-playbook --vault-password-file pass.txt master.yaml -vv
                            </SyntaxHighlighter>
                            <p style={{ marginTop: 14 }}>
                                The <code>--vault-password-file</code> flag is used to provide the path to the file containing the vault password. The <code>-vv</code> flag is used to increase verbosity of the output.
                                The <code>master.yaml</code> is the main playbook that runs the other playbooks.
                            </p>
                            <Header as="h4" id="notes">Notes</Header>
                            <p> The first section of ansible playbook tasks is to install docker and docker-compose on the server. Installs portainer and creates and admin user for portainer, if there is already an admin user, the playbook will give an error which is and can be ignored.
                                Then the playbook creates the traefik network and container for reverse proxy. Finally using portainer API, containers stacks are created by using the docker-compose files in the repo.
                                Take into account that the task loops through the repo variable names in the master.yaml file to create the stacks.
                            </p>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Segment basic>
                                <Header as="h3">Table of Contents</Header>
                                <Table basic="very">
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell><a href="#installation">Installation</a></Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell><a href="#usage">Usage</a></Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell><a href="#notes">Notes</a></Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                                <Header as="h3">Links</Header>
                                <Table basic="very">
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell><a href="https://github.com/I2SenseLab/AnsiblePlaybooks" target="_blank" rel="noreferrer">Ansible Playbooks Repo</a></Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell><a href="https://docs.ansible.com/ansible/latest/installation_guide/intro_installation.html" target="_blank" rel="noreferrer">Ansible Installation Guide</a></Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell><a href="https://docs.portainer.io/" target="_blank" rel="noreferrer">Portainer Docs</a></Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                                <Header as="h4">Details</Header>
                                <p>
                                    <strong>Created by:</strong> Santiago Fuentes
                                </p>
                                <p>
                                    <strong>Last Updated:</strong> 2024-09-07
                                </p>
                            </Segment>
                        </Grid.Column>
                    </Grid>
                </Segment>
            </Segment>
        </Container>
    );
}

export default DockerAnsible;
