import { Fragment, useEffect, useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { Accordion, Button, Container, Divider, Grid, Header, Icon, Image, Menu, Message, Search, Segment, Table } from "semantic-ui-react";



const UserManagement = () => {
    const [activeTab, setActiveTab] = useState('app-details');
    const [activeIndex, setActiveIndex] = useState(null);
    const [apiData, setApiData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredResults, setFilteredResults] = useState([]);

    const handleTabChange = (e, { name }) => setActiveTab(name);

    const handleClickAcc = (index) => {
        setActiveIndex(activeIndex === index ? null : index); // Toggle the active index
    };

    const importJsonData = (context) => {
        const jsonFiles = context.keys().map(context);
    
        const data = jsonFiles.map((file) => {
            return file.default || file;  // Add fallback if 'default' is undefined
        });
    
        // Sort the data by file name (assuming filenames follow the pattern 'json0' to 'json11')
        const sortedData = data.sort((a, b) => {
            const aName = context.keys().find(key => context(key) === a);
            const bName = context.keys().find(key => context(key) === b);
    
            const aNum = parseInt(aName.match(/\d+/)[0], 10);
            const bNum = parseInt(bName.match(/\d+/)[0], 10);
    
            return aNum - bNum;
        });
    
        setApiData(sortedData);
    };
    

    useEffect(() => {
        importJsonData(require.context('../api-data/user-management/', false, /\.json$/));
    }, []);

    const handleSearchChange = (e, { value }) => {
        setSearchQuery(value);
        if (value) {
            const filtered = apiData.filter(request => request.title.toLowerCase().includes(value.toLowerCase()));
            setFilteredResults(filtered);
        } else {
            setFilteredResults([]);
        }
    };

    // Handle search result selection
    const handleResultSelect = (id) => {
        setSearchQuery('');
    
        // Find the index of the selected API based on the id
        const selectedIndex = apiData.findIndex(request => request.id === id);
    
        // Close the currently open accordion if any
        setActiveIndex(null);
    
        // After closing the accordion, set the active accordion to the new index
        setTimeout(() => {
            if (selectedIndex !== -1) {
                setActiveIndex(selectedIndex);
            }
    
            // Scroll to the section using the id
            window.location.hash = `#${id}`;
        }, 150); // Add a short delay to ensure the accordion closes first
    };
    

    const contentStyle = {
        maxHeight: '79vh',  // Adjust this height as needed
        overflowY: 'auto',
        padding: '10px',
    };

    return (
        <Container style={{ minWidth: activeTab === 'api-info' ? '90%' : '80%' }}>
            <Segment basic>
                <Menu attached="top" tabular>
                    <Menu.Item name="app-details" active={activeTab === 'app-details'} onClick={handleTabChange}>
                        Application Details
                    </Menu.Item>
                    <Menu.Item name="api-info" active={activeTab === 'api-info'} onClick={handleTabChange}>
                        API Information
                    </Menu.Item>
                    <Menu.Item name="docker-setup" active={activeTab === 'docker-setup'} onClick={handleTabChange}>
                        Docker CloudBuild Setup
                    </Menu.Item>
                    <Menu.Item name="sql-setup" active={activeTab === 'sql-setup'} onClick={handleTabChange}>
                        SQL Database
                    </Menu.Item>
                </Menu>
                <Segment attached="bottom">
                    <Grid divided>
                        {
                            activeTab === 'api-info' && (
                                <Grid.Column width={3}>
                                    <Segment basic style={{ overflowY: 'auto', maxHeight: '79vh' }}>
                                        <Search
                                            placeholder="Search..."
                                            value={searchQuery}
                                            onSearchChange={handleSearchChange}
                                            results={filteredResults.map(request => ({ title: request.title, id: request.id }))}
                                            onResultSelect={(e, data) => handleResultSelect(data.result.id)}
                                            showNoResults={false}
                                            fluid
                                        />
                                        <Divider />
                                        <Table basic="very" selectable >
                                            <Table.Body>
                                                {
                                                    apiData.map((request, index) => (
                                                        <Table.Row as="a" key={`req-${index}`} onClick={() => setActiveIndex(index)} href={`#${request.id}`}>
                                                            <Table.Cell >
                                                                <Button
                                                                    color={request.method === 'GET' ? 'green' : request.method === 'POST' ? 'blue' : request.method === 'PUT' ? 'orange' : 'red'}
                                                                    content={request.method}
                                                                    style={{ marginLeft: 3 }} 
                                                                    compact
                                                                    size='tiny'
                                                                / >
                                                                {request.title}   
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    ))
                                                }
                                            </Table.Body>
                                        </Table>
                                    </Segment>
                                </Grid.Column>
                            )
                        }
                        <Grid.Column width={activeTab === 'api-info' ? 10 : 12}>
                            <div style={contentStyle}>
                                {
                                    activeTab === 'app-details' && (
                                        <Segment basic>
                                            <Header as="h2">User Management</Header>
                                            <p>
                                                The User Management app is used to manage the users of the FL2F workshop.
                                                You can manage, add, delete, and update the users of the workshop using this app as well as add roles and facilitators to each of the workshop groups.
                                                The app also allows you to transfer workshop applications to a group based on the applicants' answers to the application form.
                                            </p>
                                            <Divider />
                                            <Header as="h3" id="installation">Local Installation</Header>
                                            <p>
                                                To install the User Management app locally, you need to clone the repo from the link above and run the following commands:
                                            </p>
                                            <SyntaxHighlighter language="bash">
                                                git clone https://github.com/I2SenseLab/FL2F-User-Management-App
                                            </SyntaxHighlighter>
                                            <p style={{ marginTop: "1em" }}> Install NPM in both the frontend and backend directories</p>
                                            <SyntaxHighlighter language="bash">
                                                npm install
                                            </SyntaxHighlighter>
                                            <Divider />
                                            <Header as="h3" id="usage">Usage</Header>
                                            <p>
                                                To use the app locally, you need the google credentials file to authenticate the app with the google API, it is a json file that you need to add to the back end directory.
                                                Please ask for the credentials file from the administrator.
                                            </p>
                                            <p>
                                                To run the app we use the following command in the backend directory, this will run a the backend and the frontend concurrently.
                                            </p>
                                            <SyntaxHighlighter language="bash">
                                                npm run dev
                                            </SyntaxHighlighter>
                                            <Divider />
                                            <Header as="h3" id="notes">Notes</Header>
                                            <p>
                                                The User Management app is built using the following, the backend is built using Node.js and Express, the frontend is built using React.js.
                                                The app uses the Google NPM modules to authenticate the users and to manage the users of the workshop.
                                            </p>

                                        </Segment>
                                    )

                                }
                                {
                                    activeTab === 'api-info' && (
                                        <Segment basic>
                                            <Header as="h2">API Information</Header>
                                            <p>
                                                User management uses multiple API calls to the SQL database to manage the users of the workshop by utilization of React Redux Store with a connection to the backend express server.
                                            </p>
                                            <Divider section />
                                            
                                                {apiData.map((request, index) => (
                                                    <Fragment key={`accrd-${index}`} >
                                                        <Accordion fluid styled id={request.id}>
                                                            <Accordion.Title
                                                                active={activeIndex === index}
                                                                index={index}
                                                                onClick={() => handleClickAcc(index)}
                                                            >
                                                                <Icon name='dropdown' />
                                                                {request.title}
                                                            </Accordion.Title>
                                                            <Accordion.Content active={activeIndex === index}>
                                                                <Header as="h5" dividing>Query Parameters</Header>
                                                                {
                                                                    request.parameters === null ? (
                                                                        <p>No request parameters needed</p>
                                                                    ) : null
                                                                }
                                                                {/* Query Parameters */}
                                                                {request.parameters && (
                                                                    <>
                                                                        
                                                                        <Table basic="very" style={{ padding: '5px', marginTop: '2px' }}>
                                                                            <Table.Body>
                                                                                {request.parameters.fields?.map(param => (
                                                                                    <Table.Row key={param.name}>
                                                                                        <Table.Cell>
                                                                                            <code>{param.name}</code>
                                                                                            {param.required && (
                                                                                                <p style={{ color: 'red', fontSize: '0.9em' }}>Required</p>
                                                                                            )}
                                                                                        </Table.Cell>
                                                                                        <Table.Cell>
                                                                                            <code style={{ color: 'gray' }}>{param.type}</code><br />
                                                                                            {param.description}
                                                                                        </Table.Cell>
                                                                                    </Table.Row>
                                                                                ))}
                                                                            </Table.Body>
                                                                        </Table>
                                                                    </>
                                                                )}

                                                                {/* Responses */}
                                                                <Header as="h5" dividing>Responses</Header>
                                                                {request.responses?.map((response, index) => (
                                                                    <Fragment key={`response-${index}`}>
                                                                        <Message color={response.status === 200 || response.status === 201 ? 'green' : 'red'}>
                                                                            <Message.Header>{response.status}</Message.Header>
                                                                            <p>{response.description}</p>
                                                                        </Message>
                                                                        
                                                                        {/* Check if the response has fields */}
                                                                        {response.fields && (
                                                                            <Segment basic>
                                                                                <p style={{ marginBottom: 0 }}>
                                                                                    <strong style={{ color: 'gray' }}>Response Schema: </strong> 
                                                                                    <code>application/json</code>
                                                                                </p>
                                                                                <Table basic="very" style={{ padding: '5px', marginTop: '2px' }}>
                                                                                    <Table.Body>
                                                                                        {response.fields.map((field, fieldIndex) => (
                                                                                            <Table.Row key={`fieldres-${fieldIndex}`}>
                                                                                                <Table.Cell><code>{field.name}</code></Table.Cell>
                                                                                                <Table.Cell>
                                                                                                    <code style={{ color: 'gray' }}>
                                                                                                        {field.type}
                                                                                                    </code><br />
                                                                                                    {field.description}
                                                                                                </Table.Cell>
                                                                                            </Table.Row>
                                                                                        ))}
                                                                                    </Table.Body>
                                                                                </Table>
                                                                            </Segment>
                                                                        )}
                                                                    </Fragment>
                                                                ))}
                                                            </Accordion.Content>
                                                        </Accordion>
                                                        {
                                                            [0, 5, 14, 19].includes(index) ? <Divider /> : <br style={{ margin: '0.5px' }} /> // Add a divider after the 1st, 6th, and 15th request
                                                        }
                                                    </Fragment>
                                                ))}
                                            
                                        </Segment>
                                    )
                                }
                                {
                                    activeTab === 'docker-setup' && (
                                        <Segment basic>
                                            <Header as="h2">Docker CloudBuild Setup</Header>
                                            <p>
                                                User Management is deployed using a combination of two docker containers, one for the backend and one for the frontend, using the Google Cloud Build to build 
                                                and create the images, and lastly using a docker-compose file to run the containers inside the portainer service.
                                            </p>
                                            <Divider />
                                            <Header as="h3" id="docker-backend">Dockerfile for the backend - Important parts</Header>
                                            <p>
                                                Curl is install for testing purposes
                                            </p>
                                            <SyntaxHighlighter language="yaml">
                                                RUN apk add --no-cache curl
                                            </SyntaxHighlighter>
                                            <p style={{ marginTop: "1em" }}>
                                                The docker argument is used to pass the google credentials files to the container by then using echo to create the file.
                                            </p>
                                            <SyntaxHighlighter language="yaml">
                                                {
                                                    `ARG CREDENTIALS
RUN echo "$CREDENTIALS" > /app/credentials.json`
                                                }
                                            </SyntaxHighlighter>
                                            <p style={{ marginTop: "1em" }}>
                                                Then the file path location is added as an environment variable to the container.
                                            </p>
                                            <SyntaxHighlighter language="yaml">
                                                ENV GOOGLE_APPLICATION_CREDENTIALS=/app/credentials.json
                                            </SyntaxHighlighter>
                                            <p style={{ marginTop: "1em" }}>
                                                Note. The keyfile is passed the same way as the credentials file.
                                            </p>
                                            <Divider />
                                            <Header as="h3" id="docker-frontend">Dockerfile for the frontend - Important parts</Header>
                                            <p> 
                                                The docker file is using a two step build process, the first step is to build the production build of the react app and the second step is to copy 
                                                the build files to the nginx container.
                                            </p>
                                            <p>
                                                A env file is created by passing an docker argument using echo to create and write to the file. 
                                                Is Important to note that the env file is created before the build command is run so the file is available to the build process.
                                            </p>
                                            <SyntaxHighlighter language="yaml">
                                            {
                                                `ARG GOOGLE_CLIENT_ID
RUN echo "REACT_APP_GOOGLE_CLIENT_ID=$GOOGLE_CLIENT_ID" > .env`
                                            }
                                            </SyntaxHighlighter>
                                            <Divider />
                                            <Header as="h3" id="cloud-build">Cloud Build</Header>
                                            <p>
                                                The cloud build is used to build the images of the backend and frontend, the build is triggered by a push to the master branch of the repository.
                                                The build is done using the cloudbuild.yaml file that is located in the root of the repository.
                                            </p>
                                            <p>
                                                The file is divided into two steps using the cloud build docker name, see below, this tells google cloud to create the docker image based on the arguments selected.
                                            </p>
                                            <SyntaxHighlighter language="yaml">
                                                name: 'gcr.io/cloud-builders/docker'
                                            </SyntaxHighlighter>
                                            <p style={{ marginTop: "1em" }}>
                                                To get the values from the secrets we use add the argument --build-arg to the docker build command, we add the secretEnv label to the step to pass the secrets to the container.
                                            </p>
                                            <SyntaxHighlighter language="yaml">
                                                {
                                                    `--build-arg CREDENTIALS`
                                                }
                                            </SyntaxHighlighter>
                                            <SyntaxHighlighter language="yaml">
                                                secretEnv: ['CREDENTIALS']
                                            </SyntaxHighlighter>
                                            <p style={{ marginTop: "1em" }}>
                                                The secrets need to be assigned a location by using the following section in the cloudbuild.yaml file, this tells google cloud build where are the secrets located.
                                            </p>
                                            <SyntaxHighlighter language="yaml">
                                                {
                                                    `availableSecrets:
  secretManager:
  - versionName: projects/fl2f-1/secrets/credentials/versions/latest
    env: 'CREDENTIALS'`
                                                }
                                            </SyntaxHighlighter>
                                            <p style={{ marginTop: "1em" }}>
                                                Note. See file in the repository for more details.
                                            </p>
                                        </Segment>
                                    )

                                }
                                {
                                    activeTab === 'sql-setup' && (
                                        <Segment basic>
                                            <Header as="h2">SQL Database</Header>
                                            <p>
                                                The User Management app uses a SQL database to store the users of the workshop, the database is hosted on Google Cloud SQL and is connected to the backend using the
                                                npm module mysql2 to access the Information
                                            </p>
                                            <p>
                                                Here is just going to be an explanation on how the app uses some of the tables and relations within each other, for more general information see the SQL page in here that is the documentation app.
                                            </p>
                                            <Divider />
                                            <Header as="h3" id="users-table">Users Table</Header>
                                            <p>
                                                The 'workshopUsers' table is the main table User Management interacts with, it stores all the registered users of the workshop, the table has multiple relations with other tables to manage and store information of the workshop.
                                            </p>
                                            <Header as="h4">UML Diagram - Crow’s Foot Notation</Header>
                                            <Image src={require('../assets/workshopUsers.png')} />
                                            <Divider />
                                            <Header as="h3" id="table-relations">Table Relations</Header>
                                            <Table basic="very" fixed>
                                                <Table.Body>
                                                    <Table.Row>
                                                        <Table.Cell width={10}><strong>workshopUsers</strong> - <code>workshopGroups</code>
                                                        <p> Each group can have multiple users or none, the group is created by the facilitator and the users are added to the group by the admin.</p>
                                                        </Table.Cell>
                                                        <Table.Cell width={2}>Many to One</Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.Cell><strong>workshopUsers</strong> - <code>workshopResults</code>
                                                        <p>
                                                            Each user can have only one of the three survey results so it is one to one, only one result per user, the results are saved automatically after the user completes the survey. <br />
                                                            From column 1 to 10 is the 'preworkshop' survey, from 11 to 20 is the 'postworkshop' survey, and from 21 to 30 is the 'follow up' survey.
                                                        </p>
                                                        </Table.Cell>
                                                        <Table.Cell>One to One</Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.Cell><strong>workshopUsers</strong> - <code>workshopAuth</code>
                                                        <p>
                                                            Each user can only have one auth information that is used to login in the user using username and password or google login, there is only ine login information per user.
                                                        </p>
                                                        </Table.Cell>
                                                        <Table.Cell>One to One</Table.Cell>
                                                    </Table.Row>
                                                </Table.Body>
                                            </Table>
                                            <p>
                                                Note. Rest of the tables are explained in their respective app documentation if related to the app.
                                            </p>
                                        </Segment>
                                    )
                                }
                            </div>
                        </Grid.Column>
                        <Grid.Column width={activeTab === 'api-info' ? 3 : 4}>
                            <Segment basic>
                                {
                                    activeTab === 'api-info' ? null : (
                                        <Header as="h3">Table of Contents</Header>
                                    )
                                }
                                <Table basic="very">
                                    <Table.Body>
                                        {
                                            activeTab === 'app-details' && (
                                                <>
                                                    <Table.Row>
                                                        <Table.Cell><a href="#installation">Installation</a></Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.Cell><a href="#usage">Usage</a></Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.Cell><a href="#notes">Notes</a></Table.Cell>
                                                    </Table.Row>
                                                </>
                                            )
                                        }
                                        {
                                            activeTab === 'docker-setup' && (
                                                <>
                                                    <Table.Row>
                                                        <Table.Cell><a href="#docker-backend">Dockerfile Backend</a></Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.Cell><a href="#docker-frontend">Dockerfile Frontend</a></Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.Cell><a href="#cloud-build">Cloud Build</a></Table.Cell>
                                                    </Table.Row>
                                                </>
                                            )
                                        }
                                        {
                                            activeTab === 'sql-setup' && (
                                                <>
                                                    <Table.Row>
                                                        <Table.Cell><a href="#users-table">Users Table</a></Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.Cell><a href="#table-relations">Table Relations</a></Table.Cell>
                                                    </Table.Row>
                                                </>
                                            )
                                        }
                                    </Table.Body>
                                </Table>
                                <Header as="h3">Links</Header>
                                <Table basic="very">
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell><a href="https://github.com/I2SenseLab/FL2F-User-Management-App" target="_blank" rel="noreferrer">User Management App Repo</a></Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell><a href="https://vertabelo.com/blog/crow-s-foot-notation/" target="_blank" rel="noreferrer">Crow’s Foot Notation</a></Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                                <Header as="h4">Details</Header>
                                <p>
                                    <strong>Created by:</strong> Santiago Fuentes
                                </p>
                                <p>
                                    <strong>Last Updated:</strong> 2024-09-12
                                </p>
                            </Segment>
                        </Grid.Column>
                    </Grid>
                </Segment>
            </Segment>
        </Container>
    );
}



export default UserManagement;