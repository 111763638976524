import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Dropdown, Icon, Image, Menu, Sidebar } from 'semantic-ui-react';
import logo from "../assets/FL2F-logo.png";
import { logout, reset } from "../features/auth/authSlice";


const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const onLogout = () => {
    dispatch(logout());
    dispatch(reset());
    navigate("/login");
  };

  if (location.pathname === "/login") return null;

  return (
    <>
      <Menu  size="huge" className="desktop-menu">
        <Menu.Item as={Link} to="/">
          <Image src={logo} size="small" />
        </Menu.Item>
        <Menu.Menu position="right">
          <Menu.Item as={Link} to='/guides/admin/'> 
            Admin Guide 
          </Menu.Item>
          <Menu.Item as={Link} to='/guides/user/'>
            User Guide
          </Menu.Item>
          <Dropdown item text="Developers Tools">
            <Dropdown.Menu>
              <Dropdown.Item as={Link} to='/developers/docker-ansible/'>Docker-Ansible</Dropdown.Item>
              <Dropdown.Item as={Link} to='/developers/traefik/'>Traefik</Dropdown.Item>
              <Dropdown.Item as={Link} to='/developers/sql-database/'>SQL Database</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown item text="Applications">
            <Dropdown.Menu>
              <Dropdown.Item as={Link} to='/apps/operation-tracker/'>Operation Tracker</Dropdown.Item>
              <Dropdown.Item as={Link} to='/apps/user-management/'>User Management</Dropdown.Item>
              <Dropdown.Item as={Link} to='/apps/meeting-scheduler/'>Meeting Scheduler</Dropdown.Item>
              <Dropdown.Item as={Link} to='/apps/calendar-generator/'>Calendar Generator</Dropdown.Item>
              <Dropdown.Item as={Link} to='/apps/peer-assessment/'>Peer Assessment</Dropdown.Item>
              <Dropdown.Item as={Link} to='/apps/enterpreneurship/'>Enterpreneurship</Dropdown.Item>
              <Dropdown.Item as={Link} to='/apps/course/'>Course</Dropdown.Item>
              <Dropdown.Item as={Link} to='/apps/surveys/'>Surveys</Dropdown.Item>
              <Dropdown.Item as={Link} to='/apps/triz/'>TRIZ</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Menu.Item name="Logout" onClick={onLogout}>
            Logout
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    </>
  );
};

export default Navbar;
