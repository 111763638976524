import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import PrivateRoutes from "./features/utils/PrivateRoutes";
import AdminGuide from "./pages/AdminGuide";
import DockerAnsible from "./pages/DockerAnsible";
import ErrorPage from "./pages/ErrorPage";
import Home from "./pages/Home";
import Login from "./pages/Login";
import SQL from "./pages/SQL";
import Traefik from "./pages/Traefik";
import UserManagement from "./pages/UserManagement";

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<PrivateRoutes />}> {/* Wrap protected routes inside PrivateRoutes */}
            <Route path="/" element={<Home />} />
            <Route path="/guides/admin" element={<AdminGuide />} />
            <Route path="/developers/docker-ansible/" element={<DockerAnsible />} />
            <Route path="/developers/traefik/" element={<Traefik />} />
            <Route path="/developers/sql-database/" element={<SQL />} />
            <Route path="/apps/user-management/" element={<UserManagement />} />
            <Route path="/error" element={<ErrorPage />} />
            <Route path="*" element={<ErrorPage />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;

