import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Divider, Grid, Header, Icon, Item, List, Placeholder, Search, Segment } from 'semantic-ui-react';
import apps from '../assets/apps.json';

const Home = () => {
    // Split the apps into two halves
    const half = Math.ceil(apps.length / 2);
    const firstHalf = apps.slice(0, half);
    const secondHalf = apps.slice(half);

    // Placeholder search function
    const handleSearchChange = (e, { value }) => {
        console.log('Search value:', value);
    };

    return (
        <Segment basic>
            <Header as='h1' textAlign='center'>
                Documentation Dashboard
                <Header.Subheader>
                    Here is the documentation for the FL2F Workshop, including user and admin guides as well as developer documentation.
                </Header.Subheader>
            </Header>
            <Grid stackable columns='equal'>
                <Grid.Row style={{ marginLeft: '1em', marginRight: '1em' }}>
                    <Grid.Column style={{ display: 'flex', flexDirection: 'column' }} className='app-list-1' only="computer mobile">
                        <Segment style={{ flex: 1, overflow: 'auto', minWidth: "200px", display: 'flex', flexDirection: 'column' }}>
                            <Item.Group divided style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                                {firstHalf.map((app, index) => (
                                    <Item key={index} style={{ flexGrow: 1 }}>
                                        <Item.Content>
                                            <Item.Header as="h4">{app.name}</Item.Header>
                                            <Item.Meta>
                                                <a href={app.url} target="_blank" rel="noopener noreferrer">Go to Website</a>
                                            </Item.Meta>
                                            <Item.Description>
                                                <p>{app.description}</p>
                                            </Item.Description>
                                            <Item.Meta>
                                                <a href={app.github} target="_blank" rel="noopener noreferrer">Github</a>
                                            </Item.Meta>
                                        </Item.Content>
                                    </Item>
                                ))}
                            </Item.Group>
                        </Segment>
                    </Grid.Column>
                    <Grid.Column style={{ display: 'flex', flexDirection: 'column' }} only="computer mobile">
                        <Segment style={{ flex: 1, overflow: 'auto', minWidth: "200px", display: 'flex', flexDirection: 'column' }}>
                            <Item.Group divided style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                                {secondHalf.map((app, index) => (
                                    <Item key={index} style={{ flexGrow: 1 }}>
                                        <Item.Content>
                                            <Item.Header as="h4">{app.name}</Item.Header>
                                            <Item.Meta>
                                                <a href={app.url} target="_blank" rel="noopener noreferrer">Go to Website</a>
                                            </Item.Meta>
                                            <Item.Description>
                                                <p>{app.description}</p>
                                            </Item.Description>
                                            <Item.Meta>
                                                <a href={app.github} target="_blank" rel="noopener noreferrer">Github</a>
                                            </Item.Meta>
                                        </Item.Content>
                                    </Item>
                                ))}
                            </Item.Group>
                        </Segment>
                    </Grid.Column>

                    {/*single app list for tablets and mobile */}
                    <Grid.Column style={{ display: 'flex', flexDirection: 'column' }} only="tablet">
                        <Segment style={{ flex: 1, overflow: 'auto', minWidth: "200px" }} >
                            <Item.Group divided>
                                {apps.map((app, index) => (
                                    <Item key={index}>
                                        <Item.Content>
                                            <Item.Header as="h4">{app.name}</Item.Header>
                                            <Item.Meta>
                                                <a href={app.url} target="_blank" rel="noopener noreferrer">Go to Website</a>
                                            </Item.Meta>
                                            <Item.Description>
                                                <p>{app.description}</p>
                                            </Item.Description>
                                            <Item.Meta>
                                                <a href={app.github} target="_blank" rel="noopener noreferrer">Github</a>
                                            </Item.Meta>
                                        </Item.Content>
                                    </Item>
                                ))}
                            </Item.Group>
                        </Segment>
                    </Grid.Column>

                    <Grid.Column style={{ display: 'flex', flexDirection: 'column', minWidth: "66%" }} >
                        <Segment style={{ flex: 1 }}>
                            <Grid>
                                <Grid.Row columns='equal' verticalAlign='middle'>
                                    <Grid.Column width={12}>
                                        <Header as='h2' textAlign='left' style={{ marginTop: '0.2em', marginLeft: '0.2em' }}>Guides</Header>
                                    </Grid.Column>
                                    <Grid.Column width={4} textAlign='right'>
                                        <Search
                                            fluid
                                            placeholder="Search Guides..."
                                            onSearchChange={handleSearchChange}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <Divider />
                            <Segment basic>
                                <Header as='h3'>Admin Guide</Header>
                                <p>This guide includes information on how to manage users, applications, and other resources for the successful operation of the FL2F Workshop as a Operator and Administrator</p>
                                <List bulleted>
                                    <List.Item>
                                        <List.Content>Manage groups, users and workshop registration</List.Content>
                                    </List.Item>
                                    <List.Item>
                                        <List.Content>Manage emails, courses and calendars</List.Content>
                                    </List.Item>
                                    <List.Item>
                                        <List.Content>Tips and tricks for managing the workshop</List.Content>
                                    </List.Item>
                                </List>
                            </Segment>
                            <Divider />
                            <Segment basic>
                                <Header as='h3'><Link to='/guides/user/'>User Guide</Link></Header>
                                <Placeholder>
                                    <Placeholder.Paragraph>
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                    </Placeholder.Paragraph>
                                </Placeholder>
                            </Segment>
                        </Segment>
                        <Segment style={{ flex: 1 }}>
                            <Grid>
                                <Grid.Row columns='equal' verticalAlign='middle'>
                                    <Grid.Column width={11}>
                                        <Header as='h2' textAlign='left' style={{ marginTop: '0.2em', marginLeft: '0.2em' }}>Developer Tools and Deployment Resources</Header>
                                    </Grid.Column>
                                    <Grid.Column width={5} textAlign='right'>
                                        <Search
                                            fluid
                                            placeholder="Search Developer Tools..."
                                            onSearchChange={handleSearchChange}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <Divider />
                            <Grid columns={3} divided stackable>
                                <Grid.Row>
                                    <Grid.Column >
                                        <Segment basic>
                                            <Header as='h3'>Portainer/Docker Info and Ansible</Header>
                                            <p>
                                                FL2F Workshop Applications uses Docker containers for deployment and management. Portainer is a lightweight management UI
                                                which allows you to easily manage your Docker hosts, we are currently using Portainer business edition. Ansible is a simple IT automation
                                                tool that automates software applications deployment, is currently setup to deploy all of the workshop services.
                                            </p>
                                        </Segment>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Segment basic>
                                            <Header as='h3'>Traefik Reverse Proxy</Header>
                                            <p>For DNS and SSL termination, we are using Traefik reverse proxy to route traffic to the correct container.
                                                Traefik is a modern HTTP reverse proxy and load balancer that makes deploying microservices easy.
                                                It is used to route traffic to the correct container and manage SSL certificates for the workshop services.</p>
                                        </Segment>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Segment basic>
                                            <Header as='h3'>SQL Information</Header>
                                            <p>The workshop uses a MySQL database to store user information, course information, and other data. Deployed in Google Cloud SQL.
                                                Access is managed through the Google Cloud Console secret manager and the database is managed through the Google Cloud SQL console.</p>
                                        </Segment>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    );
}

export default Home;
