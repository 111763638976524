import { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { Container, Divider, Grid, Header, Image, Menu, Segment, Table } from "semantic-ui-react";

const Traefik = () => {
    const [activeTab, setActiveTab] = useState('Docker Compose');

    const handleTabChange = (e, { name }) => setActiveTab(name);


    const dockerComposeExample = `labels: ##labels for traefik, to route the traffic and to enable https
    traefik.enable: "true"
    traefik.http.routers.operation.rule: "Host('operations.fl2f.ca')"
    traefik.http.routers.operation.entrypoints: "https"`

    const yaml1 = `ports:
    - 80:80
    - 443:443
    # -- (Optional) Enable Dashboard, don't do in production
    - 8080:8080`;

    const yaml2 = `networks:
    traefik-fl2f
        external: true
        driver: bridge`;

    const yaml3 = `http:
    address: :80
    http:
      redirections:
        entryPoint:
          to: https
          scheme: https`;

    const yaml4 = `https:
    address: :443
    http:
      tls:
        certResolver: letsencrypt
        domains:
          - main: fl2f.ca
            sans:
              - 'user-management.fl2f.ca'
              - 'meetings.fl2f.ca'
              - 'course.fl2f.ca'
              - 'operations.fl2f.ca'
              - 'traefik.fl2f.ca'
              - 'survey.fl2f.ca'
              - 'portainer.fl2f.ca'
              - 'calendar.fl2f.ca'
              - 'peer-assessment.fl2f.ca'
              - 'investment.fl2f.ca'
              - 'entrepreneurship.fl2f.ca'
              - 'triz.fl2f.ca'
              - 'doc.fl2f.ca'
      middlewares:
        - securityHeaders@file`;

    const yaml5 = `http:
    routers:
        entrepreneurship:
            entryPoints:
                - https
            rule: 'Host('entrepreneurship.fl2f.ca')'
            service: entrepreneurship
      

    services:
        entrepreneurship:
        loadBalancer:
            servers:
                - url: https://136.159.102.229/`
        


    const contentStyle = {
        maxHeight: '80vh',  // Adjust this height as needed
        overflowY: 'auto',
        padding: '10px',
    };

    return (
        <Container style={{ minWidth: '70%' }}>
            <Segment basic>
                <Segment style={{ marginTop: "0.2em" }}>
                    <Grid divided>
                        <Grid.Column width={12}>
                            <div style={contentStyle}>
                                <Header as="h2" dividing>Traefik Reverse Proxy</Header>
                                <p>
                                    Traefik is a modern HTTP reverse proxy and load balancer that makes deploying microservices easy. 
                                    It supports several backends (Docker, Swarm, Kub  ernetes, Marathon, Consul, Etcd, Rancher, Amazon ECS, and a lot more) to manage its configuration automatically and dynamically.
                                    In the FL2F workshop is deployed using Docker and Ansible for automatic setup, configuration and deployment of the apps.
                                </p>
                                <Header as="h3" dividing id="deployment">Deployment</Header>
                                <Image src="https://doc.traefik.io/traefik/assets/img/quickstart-diagram.png" alt="Traefik Docker Quickstart" />
                                <p>
                                    Traefik works by watching the Docker daemon for specific labels in the containers and automatically creates the routes and configurations based on those labels.
                                    The container needs to be in the same network as the traefik container and have the labels defined in the docker-compose file.
                                </p>
                                <Header as="h4" dividing id="docker-compose-example">Docker Compose Example</Header>
                                <SyntaxHighlighter language="yaml" showLineNumbers={true}>
                                {dockerComposeExample}
                                </SyntaxHighlighter>
                                <p>
                                    The label traefik.enable: "true" enables traefik for the container. <br />
                                    The traefik.http.routers.operation.rule: "Host('operations.fl2f.ca')" routes the traffic to the container with the hostname. <br />
                                    The traefik.http.routers.operation.entrypoints: "https" specifies that the entrypoint for the router is https. <br />
                                </p>
                                <p>
                                    Note. traefik.http.routers.'name'.*: "value" are the labels that traefik uses, the name can be anything and the value is the configuration for the router.
                                </p>
                                <Header as="h3" dividing id="configuration">Configuration - Important Notes </Header>
                                <p>
                                    Traefik deployment consists of three main parts, the docker-compose file, the traefik configuration file and the dynamic configuration file. 
                                    Docker compose file is used to deploy the traefik container, the traefik configuration file is used to configure traefik and the dynamic configuration file is used to configure the dynamic routing of the traffic.
                                </p>
                                <Menu tabular widths={3}>
                                    <Menu.Item name="Docker Compose" active={activeTab === 'Docker Compose'} onClick={handleTabChange} />
                                    <Menu.Item name="Traefik Configuration" active={activeTab === 'Traefik Configuration'} onClick={handleTabChange} />
                                    <Menu.Item name="Dynamic Configuration" active={activeTab === 'Dynamic Configuration'} onClick={handleTabChange} />
                                </Menu>
                                {activeTab === 'Docker Compose' && (
                                    <Segment basic>
                                        <p>Url ports that traefik uses to route the traffic, 80 is for http and 443 is for https. The 8080 port is for the traefik dashboard.</p>
                                        <SyntaxHighlighter language="yaml" showLineNumbers={true}>
                                            {yaml1}
                                        </SyntaxHighlighter>
                                        <Divider />
                                        <p> For the certificates volume we use the acme.json file that is mapped to a docker volume so it not lost when the container is removed or updated since there is a limit on the number of certificates that can be requested from letsencrypt. </p>
                                        <SyntaxHighlighter language="yaml">
                                            {`-  traefik-certs:/etc/traefik/certs`}
                                        </SyntaxHighlighter>
                                        <Divider />
                                        <p> The environment variables Porkbun api key and secret are used to request DNS verification other providers can be used as well. </p>
                                        <SyntaxHighlighter language="yaml">
                                            {`-  PORKBUN_API_KEY=xxxxxx, PORKBUN_SECRET_API_KEY=xxxxxx`}
                                        </SyntaxHighlighter>
                                        <Divider />
                                        <p> Docker network that traefik uses must be the same as the network that the containers are in. </p>
                                        <SyntaxHighlighter language="yaml" showLineNumbers={true}>
                                            {yaml2}
                                        </SyntaxHighlighter>
                                    </Segment>
                                )}
                                {activeTab === 'Traefik Configuration' && (
                                    <Segment basic>
                                        <p>
                                            Entrypoint is the port that traefik uses to route the traffic, the ones we use in our configuration are http and https.
                                        </p>
                                        <Divider />
                                        <p> For the http is just a force redirect to https. </p>
                                        <SyntaxHighlighter language="yaml" showLineNumbers={true}>
                                            {yaml3}
                                        </SyntaxHighlighter>
                                        <Divider />
                                        <p> For the https port is defined by port 443 using the cert resolver letsencrypt and the domains are defined in the main and sans fields, 
                                            they can also be defined in the labels of the docker-compose for their respective containers. <br />
                                            The middlewares section is used to select the default middlewares that the entrypoint uses, it is defined in the dynamic configuration file, in this case is
                                            used for extra security headers.
                                        </p>
                                        <SyntaxHighlighter language="yaml" showLineNumbers={true}>
                                            {yaml4}
                                        </SyntaxHighlighter>
                                    </Segment>
                                )}
                                {activeTab === 'Dynamic Configuration' && (
                                    <Segment basic>
                                        <p> The dynamic configuration file is used to configure the dynamic routing of the traffic, it is used to define the middlewares, routers and services that traefik uses. </p>
                                        <Divider />
                                        <p> For endpoints that are not a docker container it can be added by defining the service and the router in the dynamic configuration file. See the example below. </p>
                                        <SyntaxHighlighter language="yaml" showLineNumbers={true}>
                                            {yaml5}
                                        </SyntaxHighlighter>
                                        <Divider />
                                        <p> The two middlewares used in the FL2F deployment are the security headers the security headers are used 
                                            to add extra security headers to the response it is the default used in all the endpoints. See repo for more details. </p>
                                    </Segment>
                                )}
                            </div>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Segment basic>
                                <Header as="h3">Table of Contents</Header>
                                <Table  basic="very">
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell><a href="#deployment">Deployment</a></Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell><a href="#docker-compose-example">Docker Compose Example</a></Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell><a href="#configuration">Configuration</a></Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                                <Header as="h3">Links</Header>
                                <Table basic="very">
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell><a href="https://doc.traefik.io/traefik/" target="_blank" rel="noreferrer">Traefik Documentation</a></Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell><a href="https://github.com/I2SenseLab/traefik-fl2f-proxy" target="_blank" rel="noreferrer">Traefik FL2F Proxy</a></Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                                <Header as="h4">Details</Header>
                                <p>
                                    <strong>Created by:</strong> Santiago Fuentes
                                </p>
                                <p>
                                    <strong>Last Updated:</strong> 2024-09-11
                                </p>
                            </Segment>
                        </Grid.Column>
                    </Grid>
                </Segment>
            </Segment>
        </Container>
    );
}

export default Traefik;
